var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('stockIn.pageTitle'),"breadcrumb":[
      { text: _vm.$t('home'), href: '/' },
      {
        text: _vm.$t('stockIn.pageTitle'),
        href: '/admin/inventory/stock-ins',
      },
      {
        text: _vm.$t('create'),
        active: true,
      } ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('ts-loading-banner',{staticClass:"tw-space-y-5",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"tw-space-y-5"},[_c('PurchaseOrderNumber',{ref:"purchase_number",staticClass:"tw-p-4",attrs:{"validate":_vm.errors},on:{"loading":function (v) { return (_vm.loading = v); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('ProductDetail',{staticClass:"tw-p-4 tw-max-h-screen",attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-x-4 tw-flex tw-justify-end"},[_c('ts-button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'stock-in' })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.saveAddNew.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("saveAddNew")))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("save")))])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }