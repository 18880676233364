<template>
  <div>
    <ts-page-title
      :title="$t('stockIn.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('stockIn.pageTitle'),
          href: '/admin/inventory/stock-ins',
        },
        {
          text: $t('create'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <ts-loading-banner :loading="loading" class="tw-space-y-5">
          <div class="tw-space-y-5">
            <PurchaseOrderNumber
              v-model="model"
              :validate="errors"
              @loading="(v) => (loading = v)"
              class="tw-p-4"
              ref="purchase_number"
            />
            <ProductDetail
              v-model="model"
              :validate="errors"
              class="tw-p-4 tw-max-h-screen"
            />
          </div>
          <div class="row">
            <div class="col-md-12 tw-space-x-4 tw-flex tw-justify-end">
              <ts-button @click.prevent="$router.push({ name: 'stock-in' })">{{
                $t("cancel")
              }}</ts-button>
              <ts-button
                color="primary"
                outline
                :waiting="waiting_new || waiting"
                @click.prevent="saveAddNew"
                >{{ $t("saveAddNew") }}</ts-button
              >
              <ts-button
                color="primary"
                :waiting="waiting_new || waiting"
                @click.prevent="save"
                >{{ $t("save") }}</ts-button
              >
            </div>
          </div>
        </ts-loading-banner>
      </ts-panel-wrapper>
    </ts-panel>
  </div>
</template>

<script>
import moment from "moment";
import { Errors } from "form-backend-validation";
import ProductDetail from "./components/product-detail.vue";
import PurchaseOrderNumber from "./components/purchase-order-number.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "stockInCreate",
  components: {
    ProductDetail,
    PurchaseOrderNumber,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      waiting_new: false,
      waiting: false,
      show_pdf: false,
      src: null,
      model: {
        shop_id: null,
        invoice_no: null,
        stock_in_date: moment().format("DD-MM-YYYY"),
        fix_rate: null,
        remark: null,
        product_detail: [],
      },
    };
  },
  computed: {
    ...mapState("inventory/stockIn", ["formModels"]),
  },
  methods: {
    ...mapActions("inventory/stockIn", ["getFormViewData", "store"]),
    async fetchFormView({ params, index }) {
      this.loading = true;
      await this.getFormViewData({ params: params, index: index })
        .catch((err) => {
          this.$notify({ type: "error", text: err.message });
        })
        .finally(() => {
          this.loading = false;
        });
      this.$refs.purchase_number.fetchIfShop()
    },
    save() {
      this.loading = true;
      this.errors = new Errors();
      this.store(this.model)
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.$router.push({ name: "stock-in" });
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveAddNew() {
      this.loading = true;
      this.errors = new Errors();
      this.store(this.model)
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.clearInput();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearInput() {
      this.model.shop_id = null;
      this.model.invoice_no = null;
      this.model.remark = null;
      this.model.fix_rate = null;
      this.model.stock_in_date = moment().format("DD-MM-YYYY");
      this.model.product_detail = [];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchFormView({ params: {}, index: undefined });
    });
  },
};
</script>